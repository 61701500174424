import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import useAuthorizedApi, {
  AuthorizedApi,
} from "src/helpers/hooks/app/useAuthorizedApi";
import { QueryId, getLiteQueryKey } from "src/lite/queries";
import useMerchantId from "../../../helpers/hooks/app/useMerchantId";
import { toTzAgnosticIsoDate } from "../../helpers/datetime";
import { offset } from "@popperjs/core";
import { CollectionDataOrderBy, CollectionOrderBy } from "../../../api/types";

const getCollectionsDataQuery = async (
  api: AuthorizedApi,
  merchantId: string,
  from_date: string,
  to_date: string,
  offset: number,
  page_size: number,
  search_query: string | undefined,
  order_by: CollectionDataOrderBy
) => {
  const response = await api.POST(`/collections/data-sorted-by`, {
    params: {
      query: {
        merchant_id: merchantId,
      },
    },
    body: {
      order_by: order_by,
      from_date: from_date,
      to_date: to_date,
      offset: offset,
      page_size: page_size,
      query: search_query,
    },
  });

  return getData(response);
};

const getCollectionsQuery = async (
  api: AuthorizedApi,
  merchantId: string,
  offset: number,
  page_size: number,
  search_query: string | undefined,
  order_by: CollectionOrderBy
) => {
  const response = await api.POST(`/collections/sorted-by`, {
    params: {
      query: {
        merchant_id: merchantId,
      },
    },
    body: {
      order_by: order_by,
      offset: offset,
      page_size: page_size,
      query: search_query,
    },
  });

  return getData(response);
};

interface CollectionWithDataQueryParams {
  from_date: Date;
  to_date: Date;
  page_size: number;
  search_query: string | undefined;
  order_by: CollectionOrderBy | CollectionDataOrderBy;
}

export const useCollectionsWithData = ({
  from_date,
  to_date,
  page_size,
  search_query,
  order_by,
}: CollectionWithDataQueryParams) => {
  const { api } = useAuthorizedApi();
  const { merchantId } = useMerchantId();

  const fromDateString = toTzAgnosticIsoDate(from_date);
  const toDateString = toTzAgnosticIsoDate(to_date);

  const queryKey = getLiteQueryKey(QueryId.GetSortedCollectionData, {
    merchantId: merchantId,
    from_date: !(order_by === "updated_at" || order_by === "name_a_z")
      ? fromDateString
      : undefined,
    to_date: !(order_by === "updated_at" || order_by === "name_a_z")
      ? toDateString
      : undefined,
    page_size: page_size,
    search_query: search_query,
    order_by: order_by,
  });

  return useInfiniteQuery({
    queryKey,
    queryFn: async (context: { pageParam: undefined | number }) => {
      if (!api) {
        throw new Error("api is not available");
      }

      if (order_by === "updated_at" || order_by === "name_a_z") {
        return getCollectionsQuery(
          api,
          merchantId,
          context.pageParam || 0,
          page_size,
          search_query,
          order_by
        );
      } else {
        return getCollectionsDataQuery(
          api,
          merchantId,
          fromDateString,
          toDateString,
          context.pageParam || 0,
          page_size,
          search_query,
          order_by
        );
      }
    },
    enabled: !!api,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      if (lastPage.has_next) {
        return lastPage.offset;
      } else {
        return null;
      }
    },
  });
};

export default useCollectionsWithData;
