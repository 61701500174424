import { createContext, useContext, useState } from "react";
import { useWindowSize } from "usehooks-ts";
import Confetti from "react-confetti";

type ConfettiContextType = {
  startConfetti: () => void;
};

const ConfettiContext = createContext<ConfettiContextType>({
  startConfetti: () => {},
});

export const useConfetti = () => {
  const context = useContext(ConfettiContext);

  return context.startConfetti;
};

export const ConfettiProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [on, setOn] = useState(false);
  const startConfetti = () => {
    setOn(true);
  };
  const { width, height } = useWindowSize();
  return (
    <ConfettiContext.Provider value={{ startConfetti }}>
      {on && (
        <Confetti
          width={width}
          height={height}
          run={on}
          recycle={false}
          onConfettiComplete={() => {
            setOn(false);
          }}
        />
      )}
      {children}
    </ConfettiContext.Provider>
  );
};
