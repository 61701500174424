import { css } from "@emotion/react";
import { Button } from "../alignUI/Button/Button";
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import { useIntercom } from "react-use-intercom";
import { Badge } from "src/alignUI/Badges/Badge";
import { useEffect, useState } from "react";
import { standalone } from "src/monitoring";
import { ArrowSquareOut } from "@phosphor-icons/react";
import config from "src/config";

export enum ErrorType {
  NotFound,
  Unauthorized,
  Forbidden,
}

function errorTypeInfo(t?: ErrorType) {
  switch (t) {
    case ErrorType.NotFound:
      return {
        title: "Page Not Found",
        description: "The page at this URL could not be found.",
        retryable: false,
      };
    case ErrorType.Unauthorized:
      return {
        title: "Unauthorized",
        description:
          "You are no longer logged in. Your session may have expired.",
        retryable: false,
      };
    case ErrorType.Forbidden:
      return {
        title: "Forbidden",
        description: "Permission missing to view this page.",
        retryable: false,
      };
  }
  return {
    title: "Unhandled error",
    description: (
      <>
        Something unexpected went wrong.
        <br />
        We’re on it!
      </>
    ),
    retryable: true,
  };
}

function errorTypeInfoFromError(error: unknown) {
  if (isRouteErrorResponse(error) || error instanceof Response) {
    switch (error.status) {
      case 401:
        return errorTypeInfo(ErrorType.Unauthorized);
      case 403:
        return errorTypeInfo(ErrorType.Forbidden);
      case 404:
        return errorTypeInfo(ErrorType.NotFound);
    }
  }
  return errorTypeInfo();
}

export function DepictLiteErrorPage({ errorType }: { errorType?: ErrorType }) {
  const navigate = useNavigate();
  const { showNewMessage } = useIntercom();
  const [eventId, setEventId] = useState<string>();
  const error = useRouteError();

  const errorInfo = errorType
    ? errorTypeInfo(errorType)
    : errorTypeInfoFromError(error);

  useEffect(() => {
    // We shouldn't need to capture the error here, since the Sentry<->React Router integration should do it for us.
    // And in local development, we don't need to. However, in production builds, for whatever reason, Sentry does not capture the error unless we manually capture it here.
    // If you remove this, confirm that exceptions that cause this page to render are still captured in Sentry.
    Sentry.captureException(error);

    return Sentry.getCurrentScope()
      .getClient()
      ?.on("afterSendEvent", (event) => {
        if (!event.type) {
          setEventId(event.event_id);
        }
      });
  }, [error]);

  return (
    <div
      css={(theme) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: ${theme.colors.bg["white-0"]};
      `}
    >
      <div
        css={css`
          display: flex;
          margin: 32px 0;
          flex-direction: column;
          row-gap: 20px;
          max-width: 480px;
        `}
      >
        <h1 css={(theme) => theme.typography.h5}>
          <div
            css={css`
              margin-bottom: 8px;
            `}
          >
            <Badge _style="lighter" color="red" text={errorInfo.title} />
          </div>
          {errorInfo.description}
        </h1>

        {errorInfo.retryable ? (
          <p>
            Please try again. If the issue persists, feel free to open the chat
            for assistance. We’re here to help!
          </p>
        ) : (
          <p>
            If you believe this to be wrong, feel free to open the chat for
            assistance. We’re here to help!
          </p>
        )}

        <div
          css={css`
            display: flex;
            gap: 12px;
          `}
        >
          <Button
            _style="stroke"
            variant="neutral"
            onClick={() => {
              history.back();
            }}
          >
            Go back
          </Button>
          <Button
            _style="stroke"
            variant="neutral"
            onClick={() => {
              navigate("/");
            }}
          >
            Go to Dashboard
          </Button>
          <Button
            _style="stroke"
            variant="neutral"
            onClick={() =>
              showNewMessage(
                eventId
                  ? `${errorInfo.title} error (event ID: ${eventId}):${"\n"}`
                  : `${errorInfo.title} error:${"\n"}`
              )
            }
          >
            Open chat
          </Button>
        </div>
        {eventId && (
          <div
            css={[
              (theme) => theme.typography["paragraph-xs"],
              (theme) => css`
                color: ${theme.colors.text["sub-600"]};
              `,
            ]}
          >
            {standalone && config.sentry.org && config.sentry.project ? (
              <>
                Event:{" "}
                <a
                  href={`https://sentry.io/${config.sentry.org}/${config.sentry.project}/events/${eventId}`}
                  rel="external nofollow"
                  target="_blank"
                >
                  {eventId}
                  <ArrowSquareOut />
                </a>
              </>
            ) : (
              <>Event: {eventId}</>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
