import { useAppBridge, type ShopifyGlobal } from "@shopify/app-bridge-react";

export function useMaybeAppBridge() {
  let appBridge: ShopifyGlobal | undefined;
  try {
    // To enable stand-alone mode, we need to accept failure when trying to use App Bridge.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    appBridge = useAppBridge();
  } catch {
    // Standalone mode, or configuration error
  }
  return appBridge;
}
