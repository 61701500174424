import { css, useTheme } from "@emotion/react";
import { CaretCircleLeft, X } from "@phosphor-icons/react";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { SidebarContent } from "./SidebarContent";
import { SidebarFooter } from "./SidebarFooter";
import { SidebarHeader } from "./SidebarHeader";

import { dragToSelectAndDeselectClass } from "../../lite/views/Collection/DragAndDrop/useDragToSelect";

const desktopVariants: Variants = {
  collapsed: {
    width: 80,
  },
  expanded: {
    width: 272,
  },
};

interface SidebarProps {
  persona: string;
  email: string;
  merchantId: string;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}
export const DesktopSidebar = (props: SidebarProps) => {
  const theme = useTheme();
  const { collapsed, setCollapsed } = props;

  return (
    <motion.div
      initial={"expanded"}
      animate={collapsed ? "collapsed" : "expanded"}
      variants={desktopVariants}
      className={dragToSelectAndDeselectClass}
      css={css`
        display: none;
        height: 100vh;
        // Use dvh (when supported) so on iPad the sidebar doesn't overflow when the URL bar is visible
        height: 100dvh;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        border-right: 1px solid ${theme.colors.stroke["soft-200"]};
        background: ${theme.colors.bg["white-0"]};
        padding: 24px 20px;
        position: sticky;
        top: 0;

        @media (min-width: ${theme.breakpoints.sm}px) {
          display: flex;
        }
      `}
    >
      <SidebarHeader
        collapsed={collapsed}
        onToggleCollapse={() => setCollapsed(!collapsed)}
        collapseIcon={<CaretCircleLeft size={20} />}
      />
      <SidebarContent
        collapsed={collapsed}
        merchantId={props.merchantId}
        isMobile={false}
      />
      <SidebarFooter
        collapsed={collapsed}
        persona={props.persona}
        email={props.email}
      />
    </motion.div>
  );
};

interface MobileSidebarProps {
  persona: string;
  email: string;
  merchantId: string;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}
export const MobileSidebar = (props: MobileSidebarProps) => {
  const theme = useTheme();
  const { collapsed, setCollapsed } = props;

  return (
    <AnimatePresence>
      {!props.collapsed && (
        <motion.div
          // animate the sidebar to slide in from the left
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          exit={{ x: "-100%" }}
          transition={{
            duration: 0.3,
            delay: 0.05,
            type: "tween",
          }}
          css={css`
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            flex-shrink: 0;
            border-right: 1px solid ${theme.colors.stroke["soft-200"]};
            background: ${theme.colors.bg["white-0"]};
            padding: 12px 22px 24px 22px;
            z-index: calc(var(--dnd-base-z-index, 0) + 100);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            @media (min-width: ${theme.breakpoints.sm}px) {
              display: none;
          `}
        >
          <SidebarHeader
            collapsed={collapsed}
            onToggleCollapse={() => setCollapsed(!collapsed)}
            collapseIcon={<X size={20} />}
          />
          <SidebarContent
            collapsed={collapsed}
            merchantId={props.merchantId}
            onNavigate={() => {
              setCollapsed(true);
            }}
            isMobile={true}
          />
          <SidebarFooter
            collapsed={collapsed}
            persona={props.persona}
            email={props.email}
            onChangeMerchant={() => {
              setCollapsed(true);
            }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
