import { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React from "react";
import { NavLink } from "react-router-dom";
import { SidebarItemLink } from "./SidebarItemLink";

export interface SidebarItemProps {
  collapsed: boolean;
  indicator: boolean;
  leftIcon?: (props: { css: Array<CSSInterpolation> }) => JSX.Element;
  rightIcon?: React.ReactNode;
  href?: string;
  title: string;
  onClick?: () => void;
}

const SidebarItem = (props: SidebarItemProps) => {
  const Link = props.href ? (
    <NavLink to={props.href || ""} onClick={props.onClick}>
      {({ isActive }) => {
        const { onClick, ...otherProps } = props;
        return <SidebarItemLink {...otherProps} isActive={isActive} />;
      }}
    </NavLink>
  ) : (
    <SidebarItemLink {...props} isActive={false} />
  );

  return (
    <NavigationMenu.Item
      css={css`
        list-style-type: none;
      `}
    >
      <NavigationMenu.Link
        css={css`
          text-decoration: none;
          color: inherit;
          display: block;

          &:hover {
            text-decoration: none;
            cursor: pointer;
          }
        `}
        asChild={true}
      >
        {Link}
      </NavigationMenu.Link>
    </NavigationMenu.Item>
  );
};

export default SidebarItem;
