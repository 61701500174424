import { css, useTheme } from "@emotion/react";
import { CheckCircle } from "@phosphor-icons/react";
import { motion } from "framer-motion";
import { Button } from "../../../../alignUI/Button/Button";
import { GettingStartedSteps } from "../WizardLayout";
import { Link } from "react-router-dom";
import { buildPathWithMerchantId } from "../../../DepictLiteRouter";
import { ROUTES } from "../../../DepictLiteRouter-ROUTES";

export const IngestionDone = ({ merchantId }: { merchantId: string }) => {
  const theme = useTheme();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      `}
    >
      <div
        css={css`
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 8px;
        `}
      >
        <CheckCircle
          size={20}
          fill={theme.colors.state.success.base}
          weight="fill"
        />
        <span
          css={[
            theme.typography["paragraph-s"],
            css`
              color: ${theme.colors.text["strong-950"]};
            `,
          ]}
        >
          Ready to use
        </span>
      </div>
      <Button variant={"primary"} _style={"filled"} asChild>
        <Link
          to={`${buildPathWithMerchantId(ROUTES.GETTING_STARTED, merchantId)}/${GettingStartedSteps.Step1}`}
        >
          Start Merchandising
        </Link>
      </Button>
    </motion.div>
  );
};
