import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { DepictLiteRouter } from "./lite/DepictLiteRouter";

const DepictLiteApp = () => {
  const router = DepictLiteRouter(import.meta.env.BASE_URL);

  useEffect(() => {
    // So we can style #root differently for lite vs full app
    const appDivClassList = document.querySelector("#root")?.classList;
    appDivClassList?.add("lite-app");
    return () => {
      appDivClassList?.remove("lite-app");
    };
  }, []);

  return <RouterProvider router={router} />;
};

export default DepictLiteApp;
