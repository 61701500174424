import usePlanStatus from "../../../helpers/usePlanStatus";

export function useSubscriptionStatus() {
  const data = usePlanStatus();

  if (!data.subscriptions || data.subscriptions.length === 0) {
    return { isPaid: false as const };
  }
  return {
    isPaid: true as const,
    ...data.subscriptions[0],
  };
}
