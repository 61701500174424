import { css, useTheme } from "@emotion/react";
import { AnimatePresence, motion } from "framer-motion";
import Logo from "../../lite/Logo";

interface SidebarHeaderProps {
  collapsed: boolean;
  onToggleCollapse: () => void;
  collapseIcon: React.ReactNode;
}
export const SidebarHeader = (props: SidebarHeaderProps) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        cursor: pointer;
      `}
      onClick={props.onToggleCollapse}
    >
      {/* Card */}
      <div
        css={css`
          padding: 12px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          background-color: ${theme.colors.bg["strong-950"]};
        `}
      >
        <Logo size={16} color={theme.colors.icon["white-0"]} />
      </div>
      {props.collapsed ? null : (
        <AnimatePresence>
          <motion.div
            key={"headerText"}
            css={css`
              display: flex;
              height: 40px;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 4px;
              flex: 1 0 0;
            `}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <span css={[theme.typography["label-s"], css``]}>Depict</span>
          </motion.div>
          <div
            key={"collapseIcon"}
            css={css`
              display: flex;
              padding: 2px;
              justify-content: center;
              align-items: center;
              gap: 2px;
              color: ${theme.colors.icon["sub-600"]};
            `}
          >
            {props.collapseIcon || null}
          </div>
        </AnimatePresence>
      )}
    </div>
  );
};
