import { useMemo, useState } from "react";

interface Props<T> {
  allItems: T[];
  pageSize: number;
  initialPage: number;
}
function useInMemoryPagination<T>(props: Props<T>) {
  const { allItems, pageSize, initialPage } = props;

  const [page, setPage] = useState(initialPage);

  const items = useMemo(
    () => allItems.slice(0, (page + 1) * pageSize),
    [allItems, page, pageSize]
  );

  const hasNextPage = items.length < allItems.length;

  const fetchNextPage = () => {
    setPage((prev) => prev + 1);
  };

  return {
    isLoading: false,
    items,
    hasNextPage,
    fetchNextPage,
  };
}

export default useInMemoryPagination;
