import { matchSorter } from "match-sorter";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CompactSelectTrigger,
  DropdownWithSearch,
} from "src/alignUI/DropdownWithSearch/DropdownWithSearch";
import useInMemoryPagination from "src/helpers/hooks/useInMemoryPagination";
import { Merchant } from "../api/types";
import { useAuthorizedMerchantsContext } from "../helpers/hooks/app/useAuthorizedMerchantsContext";
import useMerchant from "../helpers/hooks/app/useMerchant";

const getMerchantLabel = (
  merchant: Merchant,
  authorizedMerchants: Merchant[]
) => {
  const merchantsWithSameName = authorizedMerchants.filter(
    (m: Merchant) => m.display_name === merchant.display_name
  );
  if (merchantsWithSameName.length > 1) {
    return `${merchant.display_name} (${merchant.id})`;
  }
  return merchant.display_name;
};

interface MerchantSelectorProps {
  onChangeMerchant?: () => void;
}

export const MerchantSelector = ({
  onChangeMerchant,
}: MerchantSelectorProps) => {
  const { merchantId: selected, merchantOptions } = useMerchant();
  const { authorizedMerchants } = useAuthorizedMerchantsContext();

  const navigate = useNavigate();

  const selectNewMerchant = (merchantId: string) => {
    if (selected === merchantId) {
      return;
    }

    let newMerchant: Merchant | undefined = authorizedMerchants?.find(
      (m) => m.id === merchantId
    );

    if (newMerchant) {
      // Redirect to the first page (dashboard) of the new merchant to avoid being on invalid collection, etc
      const redirectPath = `/${newMerchant.id}`;
      navigate(redirectPath);

      onChangeMerchant?.();
    }
  };

  const [searchValue, setSearchValue] = useState("");

  const merchants = useMemo(() => {
    if (!authorizedMerchants) {
      return [];
    }

    return authorizedMerchants.map((merchant) => ({
      value: merchant.id,
      label: getMerchantLabel(merchant, authorizedMerchants),
    }));
  }, [authorizedMerchants]);

  const matches = useMemo(() => {
    return matchSorter(merchants, searchValue, {
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
      keys: ["label"],
    });
  }, [merchants, searchValue]);

  const merchantPagination = useInMemoryPagination({
    allItems: matches,
    pageSize: 100,
    initialPage: 0,
  });

  if (merchantOptions.length < 2) {
    return null;
  }

  return (
    <DropdownWithSearch
      placeholder="Pick an option..."
      onSelectValue={(value) => {
        selectNewMerchant(value);
      }}
      pagination={merchantPagination}
      search={{
        setSearchValue,
      }}
      selectComponent={() => <CompactSelectTrigger label={"Merchant"} />}
      sameWidth={false}
      popoverPlacement={"top-end"}
      maxPopoverWidth={230}
    />
  );
};
