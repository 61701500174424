import {
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import useOptionalMerchant from "../helpers/hooks/app/useOptionalMerchant";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import { DepictLiteErrorPage, ErrorType } from "./DepictLiteErrorPage";
import { ROUTES } from "./DepictLiteRouter-ROUTES";
import DepictLiteGlobalLayout from "./views/DepictLiteGlobalLayout/DepictLiteGlobalLayout";
import { useMaybeAppBridge } from "src/helpers/shopify/useMaybeAppBridge";
import { getCreateBrowserRouter } from "src/monitoring";
import { GettingStartedSteps } from "./views/GettingStarted/WizardLayout";
import { useMultiStoreValidation } from "./helpers/useMultiStore";
import SubStoreScreen from "./components/SubStoreScreen";
import IngestionStep, {
  TRIGGER_INSTALL_PATH_MERCHANT_ID,
} from "./views/GettingStarted/Ingestion/IngestionStep";
import * as Sentry from "@sentry/react";
import { StorefrontAPIClientProvider } from "src/helpers/hooks/useStorefrontAPIClient";
import { AdminAPIClientProvider } from "../helpers/hooks/useAdminAPI";
import Collections from "./views/Collections/Collections";

export const buildPathWithMerchantId = (route: ROUTES, merchantId: string) => {
  return route.replace(":merchantId", merchantId);
};

function MerchantBoundary() {
  const optionalMerchant = useOptionalMerchant();
  const appBridge = useMaybeAppBridge();

  if (optionalMerchant.loading && !optionalMerchant.merchant) {
    // We are still loading the merchant, show splash screen
    return <SplashScreen />;
  }

  if (!optionalMerchant.merchant) {
    // Merchant loaded, but there is none. Should only happen in embedded mode, meaning they need to install. Handled in boundaries below.
    if (!appBridge?.environment?.embedded) {
      // Superuser trying to access a non-existent merchant
      return <Navigate to={ROUTES.NO_MERCHANTS} />;
    }
  }

  return (
    <StorefrontAPIClientProvider>
      <AdminAPIClientProvider>
        <Outlet />
      </AdminAPIClientProvider>
    </StorefrontAPIClientProvider>
  );
}

function MultiStoreBoundary() {
  const { isSubStore, merchantDomain, merchantId } = useMultiStoreValidation();

  if (isSubStore && merchantDomain && merchantId) {
    // Sub stores should not access this interface at all, point them towards their parent store
    return <SubStoreScreen merchantId={merchantId} domain={merchantDomain} />;
  }

  return <Outlet />;
}

function GettingStartedRedirect() {
  const { merchant } = useOptionalMerchant();

  if (!merchant) {
    // We are not loading, yet no merchant exists. Try installing. Simply a fast path, GettingStartedCompletedBoundary would handle this as well.
    return (
      <Navigate
        to={buildPathWithMerchantId(
          ROUTES.GETTING_STARTED,
          TRIGGER_INSTALL_PATH_MERCHANT_ID
        )}
      />
    );
  }

  // We have a merchant, route to dashboard.
  // The Dashboard is covered by additional boundaries that will redirect to the correct spot in the Getting Started flow if necessary
  return (
    <Navigate
      replace
      to={buildPathWithMerchantId(ROUTES.COLLECTIONS, merchant.id)}
    />
  );
}

function GettingStartedCompletedBoundary() {
  const { merchant } = useOptionalMerchant();
  const appBridge = useMaybeAppBridge();

  if (!merchant?.shopify_app_installed) {
    // There is no merchant with the app installed. Three possibilities:
    // 1. We are in embedded mode and the merchant has not installed the app, and should see the install flow.
    // 2. We are in superuser mode and the merchant has uninstalled the app, and should see whatever the merchant last saw.
    // 3. We are in embedded mode and the merchant is accessing a substore, but we have not loaded the multi-store data yet. Ideally they would see the substore screen, but we will have to show the install flow until we have the data.

    if (appBridge?.environment?.embedded) {
      // Case 1 and 3
      return (
        <Navigate
          replace
          to={buildPathWithMerchantId(
            ROUTES.GETTING_STARTED,
            TRIGGER_INSTALL_PATH_MERCHANT_ID
          )}
        />
      );
    }

    if (!merchant) {
      // Superuser is trying to access a merchant that straight up does not exist.
      // This case is actually covered in MerchantLoadedBoundary, but Typescript doesn't know that.
      return <Navigate to={ROUTES.NOT_FOUND} />;
    }
  }

  if (!merchant.has_run_at_least_one_ingestion) {
    // Merchant exists, but ingestion hasn't completed yet, navigate to ingestion progress bar
    return (
      <Navigate
        replace
        to={buildPathWithMerchantId(ROUTES.GETTING_STARTED, merchant.id)}
      />
    );
  }

  return <Outlet />;
}

function NavigateToCollections() {
  const { merchant } = useOptionalMerchant();

  if (!merchant) {
    // Logically this should never happen, but if someone changes the routing to end up here before merchant is loaded, better safe than sorry and wait for it maybe to load.
    // Definitely log the error so we can fix our routing.
    Sentry.captureException(
      new Error("Merchant should be loaded at this point")
    );
    return <SplashScreen />;
  }

  return (
    <Navigate
      replace
      to={buildPathWithMerchantId(ROUTES.COLLECTIONS, merchant.id)}
    />
  );
}

export function DepictLiteRouter(baseName: string) {
  const createBrowserRouter = getCreateBrowserRouter();

  return createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<DepictLiteErrorPage />}>
        <Route
          path={ROUTES.NO_MERCHANTS}
          lazy={() => import("./views/NoMerchants")}
        />
        <Route
          path={ROUTES.UNAUTHORIZED}
          element={<DepictLiteErrorPage errorType={ErrorType.Unauthorized} />}
        />
        <Route
          path={ROUTES.NOT_FOUND}
          element={<DepictLiteErrorPage errorType={ErrorType.NotFound} />}
        />

        <Route element={<MerchantBoundary />}>
          <Route element={<MultiStoreBoundary />}>
            <Route index element={<GettingStartedRedirect />} />
            <Route
              path={ROUTES.DEFAULT_REDIRECT}
              element={<NavigateToCollections />}
            />

            <Route path={ROUTES.GETTING_STARTED}>
              <Route index element={<IngestionStep />} />
              <Route
                id={GettingStartedSteps.Step1}
                path={GettingStartedSteps.Step1}
                lazy={() => import("./views/GettingStarted/Steps/Columns")}
              />
              <Route
                id={GettingStartedSteps.Step2}
                path={GettingStartedSteps.Step2}
                lazy={() =>
                  import(
                    "./views/GettingStarted/Steps/SelectCollection/SelectCollection"
                  )
                }
              />
            </Route>

            <Route element={<GettingStartedCompletedBoundary />}>
              <Route element={<DepictLiteGlobalLayout />}>
                {/* This is temporary such that people lands on collections if they kept the dashboard url */}
                <Route
                  path={"/:merchantId/dashboard"}
                  element={<NavigateToCollections />}
                />
                <Route path={ROUTES.COLLECTIONS} Component={Collections} />
                <Route
                  path={ROUTES.COLLECTION}
                  lazy={() => import("./views/Collection/Collection")}
                />
                <Route
                  path={ROUTES.SETTINGS}
                  lazy={() => import("./views/Settings/Settings")}
                />
                <Route
                  path={ROUTES.MULTI_STORE_CONNECTIONS}
                  lazy={() =>
                    import("./views/Settings/MultiStoreCollectionMappingView")
                  }
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    ),
    {
      basename: baseName,
    }
  );
}
