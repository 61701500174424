import { css, useTheme } from "@emotion/react";
import { motion } from "framer-motion";
import { MerchantSelector } from "../../lite/MerchantSelector";
import { Avatar } from "./Avatar";

interface SidebarFooterProps {
  persona: string;
  email: string;
  collapsed: boolean;
  onChangeMerchant?: () => void;
}
export const SidebarFooter = (props: SidebarFooterProps) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        align-items: center;
        gap: 12px;
        background: ${theme.colors.bg["white-0"]};
        position: relative;
        padding-top: 24px;
        border-top: 1px solid ${theme.colors.stroke["soft-200"]};
      `}
    >
      <div
        css={css`
          display: flex;
          width: 100%;
          align-items: center;
          gap: 12px;
          flex-shrink: 0;
        `}
      >
        <Avatar persona={props.persona} />
        <motion.div
          initial={{ opacity: 1 }}
          animate={props.collapsed ? "collapsed" : "expanded"}
          variants={{
            expanded: {
              opacity: 1,
            },
            collapsed: {
              opacity: 0,
              display: "none",
            },
          }}
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
            position: relative;
            overflow: hidden;
          `}
        >
          <p
            css={[
              theme.typography["label-s"],
              css`
                margin: 0;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `,
            ]}
          >
            {props.persona}
          </p>
          <p
            css={[
              theme.typography["paragraph-xs"],
              css`
                margin: 0;
                color: ${theme.colors.text["sub-600"]};
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `,
            ]}
          >
            {props.email}
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 1 }}
          animate={props.collapsed ? "collapsed" : "expanded"}
          variants={{
            expanded: {
              opacity: 1,
            },
            collapsed: {
              opacity: 0,
              display: "none",
            },
          }}
        >
          <MerchantSelector onChangeMerchant={props.onChangeMerchant} />
        </motion.div>
      </div>
    </div>
  );
};
