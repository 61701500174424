import { css, useTheme } from "@emotion/react";
import { List } from "@phosphor-icons/react";

interface TopbarProps {
  onExpand: () => void;
}
const Topbar = (props: TopbarProps) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        position: sticky;
        top: 0;
        z-index: calc(var(--dnd-base-z-index, 0) + 12);

        display: flex;
        width: 100%;
        padding: 12px;
        align-items: center;
        gap: 16px;
        border-bottom: 1px solid ${theme.colors.stroke["soft-200"]};
        background: ${theme.colors.bg["white-0"]};

        @media (min-width: ${theme.breakpoints.sm}px) {
          display: none;
        }
      `}
    >
      {/* Left */}
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 12px;
          flex: 1 0 0;
        `}
      >
        <div
          css={css`
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <List
            size={20}
            color={theme.colors.icon["sub-600"]}
            onClick={props.onExpand}
          />
        </div>
        <span
          css={[
            theme.typography["label-s"],
            css`
              color: ${theme.colors.text["strong-950"]};
            `,
          ]}
        >
          Depict
        </span>
      </div>
    </div>
  );
};

export default Topbar;
