import { css } from "@emotion/react";
import queryClient from "src/queries/client";
import { getQueryKey, QueryId } from "src/queries/queries";
import { useIngestionStatus } from "../../../helpers/useIngestionStatus";
import { Logo } from "./Logo";
import { IngestionProgress } from "./IngestionProgress";
import { IngestionDone } from "./IngestionDone";
import useInstall from "../../Settings/useInstall";
import { useEffect } from "react";
import useOptionalMerchant from "../../../../helpers/hooks/app/useOptionalMerchant";
import { useMultiStoreValidation } from "src/lite/helpers/useMultiStore";
import { useParams } from "react-router-dom";
import { useAlert } from "src/alignUI/Alert/useAlert/useAlert";
import useIsMobile from "src/helpers/hooks/useIsMobile";
import { Carousel } from "src/alignUI/Carousel/Carousel";

import step01 from "src/assets/img/splash/step01.webp?url";
import step02 from "src/assets/img/splash/step02.webp?url";
import step03 from "src/assets/img/splash/step03.webp?url";
import step04 from "src/assets/img/splash/step04.webp?url";

const steps = [
  { src: step01, title: "Add video or images in the grid" },
  { src: step02, title: "Duplicate & Highlight Products" },
  { src: step03, title: "Visual Drag & Drop, multi-select" },
  { src: step04, title: "Select image, per product, per collection" },
];

// Fake merchant ID that separates real merchant IDs from "Merchant does not exist and needs to be installed in this view"
export const TRIGGER_INSTALL_PATH_MERCHANT_ID = "install";

const IngestionStep = () => {
  const isMobile = useIsMobile();
  const { merchantId: merchantIdFromParams } = useParams();
  const { merchant } = useOptionalMerchant();
  const { mutate: install, isError } = useInstall();
  const { addAlert } = useAlert();
  const { isLoadingSubStoreInfo } = useMultiStoreValidation();

  useEffect(() => {
    if (
      !merchant?.shopify_app_installed &&
      !isLoadingSubStoreInfo &&
      merchantIdFromParams === TRIGGER_INSTALL_PATH_MERCHANT_ID
    ) {
      // We should only attempt to install if the app is not installed, we were redirected here with the intent of installing, AND we are sure this is not a sub-store that will soon be redirected to the parent store
      install();
    }
  }, [merchant, isLoadingSubStoreInfo, merchantIdFromParams, install]);

  useEffect(() => {
    if (isError) {
      addAlert({
        autoClose: 5000,
        size: "large",
        status: "error",
        styling: "filled",
        text: "An unexpected error occurred. Please refresh the page and try again.",
      });
    }
  }, [isError, addAlert]);

  const { complete, completionPercentage } = useIngestionStatus({
    merchantId: merchant?.id,
    onIngestionComplete: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(QueryId.GetAuthorizedMerchants),
      });
    },
  });

  return (
    <div
      css={[
        css`
          display: flex;
          flex-direction: ${isMobile ? `column` : `row`};
          height: 100%;
          align-items: stretch;
        `,
      ]}
    >
      <div
        css={(theme) => css`
          background-color: ${theme.colors.bg["weak-50"]};
          flex: 1 1 auto;
          min-width: 0;

          // Center item in the big left side
          display: flex;
          align-items: center;
        `}
      >
        <Carousel
          css={css`
            width: 100%;
          `}
        >
          {steps.map(({ src, title }, index) => (
            <div
              key={"step-" + (index + 1)}
              css={css`
                figure {
                  // Don't make the images too tall
                  max-height: 500px;
                }
                display: flex;
                justify-content: center;
              `}
            >
              <figure
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: ${isMobile ? "10px" : "40px"};
                  align-items: center;
                  margin: 0 20px;
                `}
              >
                <figcaption
                  css={[
                    isMobile
                      ? (theme) => theme.typography.h6
                      : (theme) => theme.typography.h4,
                    css`
                      text-align: center;
                    `,
                  ]}
                >
                  {title}
                </figcaption>
                <img
                  css={css`
                    max-width: 100%;
                    min-height: 0;
                  `}
                  src={src}
                  alt={title}
                />
              </figure>
            </div>
          ))}
        </Carousel>
      </div>
      <div
        css={[
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: ${isMobile ? 16 : 40}px;
            padding: 0 20px;
          `,
          !isMobile
            ? css`
                min-width: 480px;
              `
            : css`
                flex-basis: 280px;
              `,
        ]}
      >
        <Logo />
        {!complete || !merchant ? (
          <IngestionProgress completionPercentage={completionPercentage} />
        ) : (
          <IngestionDone merchantId={merchant.id} />
        )}
      </div>
    </div>
  );
};

export default IngestionStep;
