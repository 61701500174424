import * as Ariakit from "@ariakit/react";
import { SerializedStyles, css, useTheme } from "@emotion/react";
import {
  CaretUpDown,
  Check,
  IconProps,
  MagnifyingGlass,
} from "@phosphor-icons/react";
import { startTransition, useMemo, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { AnimatedSpinnerGap } from "../AnimatedIcons/AnimatedSpinnerGap";
import { ContentDivider } from "../ContentDivider/ContentDivider";

export interface Item {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface PaginationProps {
  hasNextPage: boolean;
  isLoading: boolean;
  fetchNextPage: () => void;
  items: Item[];
}

interface DropdownWithSearchProps {
  placeholder?: string;
  defaultValue?: string;
  onSelectValue?: (value: string) => void;
  search: {
    setSearchValue: (value: string) => void;
  };
  pagination: PaginationProps;
  selectComponent?: React.ComponentType<any>;
  sameWidth?: boolean;
  flip?: Ariakit.SelectPopoverProps["flip"];
  popoverPlacement?: Ariakit.SelectProviderProps["placement"];
  maxPopoverWidth?: number;
}

export function DropdownWithSearch(props: DropdownWithSearchProps) {
  const setSearchValue = props.search.setSearchValue;

  const sameWidth = props.sameWidth === undefined ? true : props.sameWidth;

  const theme = useTheme();

  const placeholder = props.placeholder || "Select an item";
  const [latestSelectedLabel, setLatestSelectedLabel] =
    useState<string>(placeholder);

  const valueToLabel = useMemo(() => {
    const map = props.pagination.items.reduce(
      (acc, item) => {
        acc[item.value] = item.label;
        return acc;
      },
      {} as Record<string, string>
    );

    map[placeholder] = placeholder;

    return map;
  }, [placeholder, props.pagination.items]);

  const [sentryRef] = useInfiniteScroll({
    hasNextPage: props.pagination?.hasNextPage || false,
    loading: props.pagination?.isLoading || false,
    onLoadMore: props.pagination?.fetchNextPage || (() => {}),
  });

  const DefaultSelectComponent = () => (
    <Ariakit.Select
      css={[
        theme.typography["paragraph-s"],
        css`
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 1px 2px 0px ${theme.colors.neutral.static.black}08;
          border-radius: 10px;
          padding: 0 15px;
          font-size: 13px;
          line-height: 1;
          height: 35px;
          gap: 4px;
          width: 100%;
          background-color: ${theme.colors.bg["white-0"]};
          border: 1px solid ${theme.colors.stroke["soft-200"]};

          &:focus {
            outline: none;
            border-color: ${theme.colors.stroke["strong-950"]};
          }

          &:hover {
            border-color: ${theme.colors.bg["weak-50"]};
            background-color: ${theme.colors.bg["weak-50"]};
          }
        `,
      ]}
    >
      <Ariakit.SelectValue fallback={""}>
        {(value) => <>{latestSelectedLabel}</>}
      </Ariakit.SelectValue>
      <Ariakit.SelectArrow />
    </Ariakit.Select>
  );

  const SelectComponent = props.selectComponent || DefaultSelectComponent;

  return (
    <Ariakit.ComboboxProvider
      resetValueOnHide
      setValue={(value) => {
        startTransition(() => {
          setSearchValue(value);
        });
      }}
    >
      <Ariakit.SelectProvider
        placement={props.popoverPlacement}
        setValue={(value) => {
          if (Array.isArray(value)) {
            const actualValue = value[0];

            if (actualValue === placeholder) {
              return;
            }

            setLatestSelectedLabel(valueToLabel[actualValue]);
            props.onSelectValue?.(value[0]);
          } else {
            if (value === placeholder) {
              return;
            }
            setLatestSelectedLabel(valueToLabel[value]);
            props.onSelectValue?.(value);
          }
        }}
        defaultValue={props.defaultValue || placeholder}
      >
        <SelectComponent />
        <Ariakit.SelectPopover
          focusable
          focusOnMove
          gutter={4}
          sameWidth={sameWidth}
          css={css`
            z-index: calc(var(--dnd-base-z-index, 0) + 50);
            display: flex;
            max-height: min(var(--popover-available-height, 300px), 300px);
            ${props.maxPopoverWidth &&
            `max-width: min(var(--popover-available-width, ${props.maxPopoverWidth}px), ${props.maxPopoverWidth}px);`}
            flex-direction: column;
            overflow: auto;
            overscroll-behavior: contain;
            border-radius: 0.5rem;
            border-width: 1px;
            border-style: solid;
            border-color: ${theme.colors.stroke["soft-200"]};
            background-color: ${theme.colors.bg["white-0"]};
            color: black;
            box-shadow:
              0 10px 15px -3px rgb(0 0 0 / 0.1),
              0 4px 6px -4px rgb(0 0 0 / 0.1);
            padding: 0 0.5rem 0.5rem;
          `}
          flip={props.flip}
          fitViewport={true}
        >
          <div
            css={css`
              position: sticky;
              top: 0px;
              width: 100%;
              background-color: inherit;
              margin-bottom: 8px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                padding: 8px;
                gap: 8px;
              `}
            >
              <MagnifyingGlass
                size={20}
                color={theme.colors.icon["soft-400"]}
              />
              <Ariakit.Combobox
                autoFocus
                autoSelect="always"
                autoComplete="inline"
                placeholder="Search..."
                css={[
                  theme.typography["paragraph-s"],
                  css`
                    border: none;
                    outline: none;
                    flex: 1;
                    background: transparent;
                    width: 100%;
                    &:focus {
                      color: ${theme.colors.text["strong-950"]};
                    }
                  `,
                ]}
              />
            </div>
            <ContentDivider />
          </div>
          <Ariakit.ComboboxList
            css={css`
              width: 100%;
            `}
          >
            {/* Disgusting hack to not get one value selected by default, coming from AriaKit Github issues */}
            {props.defaultValue === undefined && (
              <Ariakit.SelectItem
                focusable={false}
                key=""
                value={""}
                css={[
                  css`
                    display: none;
                  `,
                ]}
              >
                {placeholder}
              </Ariakit.SelectItem>
            )}
            {props.pagination.items.map((item) => (
              <Ariakit.SelectItem
                key={item.value}
                value={item.value}
                disabled={item.disabled}
                data-disabled={item.disabled}
                render={
                  <Ariakit.ComboboxItem disabled={item.disabled}>
                    {item.label}
                  </Ariakit.ComboboxItem>
                }
                css={[
                  theme.typography["paragraph-s"],
                  css`
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px;
                    user-select: none;
                    width: 100%;
                    gap: 8px;
                    white-space: nowrap;
                    box-shadow: none;
                    &:hover {
                      background-color: ${theme.colors.bg["weak-50"]};
                    }
                    &:focus {
                      outline: none;
                    }
                    &:focus-visible {
                      outline: none;
                    }

                    &[data-disabled="true"] {
                      color: ${theme.colors.text["disabled-300"]};
                    }

                    &[data-active-item] {
                      background-color: ${theme.colors.bg["weak-50"]};
                    }
                    scroll-margin-top: 40px;
                  `,
                ]}
              >
                <Ariakit.SelectItemCheck
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <Check size={20} color={theme.colors.icon["sub-600"]} />
                </Ariakit.SelectItemCheck>
              </Ariakit.SelectItem>
            ))}
            {(props.pagination?.isLoading || props.pagination?.hasNextPage) && (
              <div
                ref={sentryRef}
                css={css`
                  align-self: flex-start;
                  padding: 8px;
                `}
              >
                <AnimatedSpinnerGap
                  weight="regular"
                  color={theme.colors.primary.base}
                  size={16}
                />
              </div>
            )}
          </Ariakit.ComboboxList>
        </Ariakit.SelectPopover>
      </Ariakit.SelectProvider>
    </Ariakit.ComboboxProvider>
  );
}

type CompactSelectTriggerProps = {
  css?: SerializedStyles;
  icon?: React.FC<IconProps>;
  label?: string;
};
export const CompactSelectTrigger = (
  props: React.PropsWithChildren<CompactSelectTriggerProps>
) => {
  const theme = useTheme();

  return (
    <Ariakit.Select
      css={[
        theme.typography["paragraph-s"],
        css`
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 1px 2px 0px ${theme.colors.neutral.static.black}08;
          border-radius: 10px;
          padding: 10px;
          font-size: 13px;
          box-sizing: border-box;
          height: 40px;
          gap: 4px;
          background-color: ${theme.colors.bg["white-0"]};

          border: 1px solid ${theme.colors.stroke["soft-200"]};

          &:focus {
            outline: none;
            border-color: ${theme.colors.stroke["strong-950"]};
          }

          &:hover {
            border-color: ${theme.colors.bg["weak-50"]};
            background-color: ${theme.colors.bg["weak-50"]};
          }
        `,
        props.css,
      ]}
      aria-label={props.label}
    >
      {props.icon && (
        <props.icon color={theme.colors.icon["strong-950"]} size={20} />
      )}
      <CaretUpDown size={20} />
    </Ariakit.Select>
  );
};
