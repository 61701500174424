import { useCallback } from "react";
import { toast } from "react-toastify";
import { Alert } from "../Alert";
import { AlertMessage } from "../Alert.types";

export function useAlert() {
  const addAlert = useCallback((alert: AlertMessage) => {
    const alertComponent = <Alert {...alert} />;

    toast(alertComponent, {
      autoClose: alert?.autoClose || 1000,
      position: "top-center",
      hideProgressBar: true,
      closeButton: false,
      bodyStyle: {
        // remove all default styles
        padding: 0,
        margin: 0,
        boxShadow: "none",
        borderRadius: 0,
        border: "none",
        background: "none",
      },
      style: {
        // remove all default styles
        padding: 0,
        margin: 0,
        boxShadow: "none",
        borderRadius: 0,
        border: "none",
        background: "none",
        width: 390,
        overflow: "visible",
      },
    });
  }, []);

  return {
    addAlert,
  };
}
