import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import { useMutation } from "@tanstack/react-query";
import { throwErrorIfAny } from "../../../../api/authorizedApi";
import queryClient from "src/queries/client";
import { QueryId, getLiteQueryKey } from "src/lite/queries";
import useMerchantId from "src/helpers/hooks/app/useMerchantId";
import { useGetStoreMetafieldQuery } from "../../../../generated/graphql";
import { useStorefrontAPIClient } from "../../../../helpers/hooks/useStorefrontAPIClient";
import { LiteColumnSettings } from "../../../../api/types";
import { setColumnSettingMetafieldData } from "./setColumnSettingsData";
import { useMemo } from "react";

export function useMutateColumnSettings() {
  const { merchantId } = useMerchantId();
  const { api } = useAuthorizedApi();

  return useMutation<void, Error, LiteColumnSettings>({
    mutationFn: async ({ desktop_columns, mobile_columns }) => {
      if (merchantId && api) {
        const response = await api.PUT(
          `/shopify-collection-template/{merchant_id}/columns-settings`,
          {
            params: {
              path: {
                merchant_id: merchantId,
              },
            },
            body: {
              desktop_columns,
              mobile_columns,
            },
          }
        );
        throwErrorIfAny(response);
      }
    },
    onMutate: async (variables) => {
      await queryClient.setQueryData(
        getLiteQueryKey(QueryId.ShopifyGetColumnSettingsMetafieldsQuery, {
          merchantId,
        }),
        setColumnSettingMetafieldData(variables)
      );
    },
    onError: async () => {
      await queryClient.invalidateQueries({
        queryKey: getLiteQueryKey(
          QueryId.ShopifyGetColumnSettingsMetafieldsQuery,
          {
            merchantId,
          }
        ),
      });
    },
  });
}

export function useColumnSettings() {
  const { merchantId } = useMerchantId();
  const storefrontAPIClient = useStorefrontAPIClient();

  const query = useGetStoreMetafieldQuery(
    storefrontAPIClient!,
    {
      namespace: "$app:column-settings",
      key: "column_settings",
    },
    {
      enabled: !!storefrontAPIClient,
      queryKey: getLiteQueryKey(
        QueryId.ShopifyGetColumnSettingsMetafieldsQuery,
        { merchantId }
      ),
      staleTime: 1000 * 60 * 5,
    }
  );

  const columnSettings: LiteColumnSettings | undefined = useMemo(() => {
    // If the settings are still being fetched, return undefined, if it's done fetching and there is no data,
    // return (4,2)
    if (query.isPending) {
      return undefined;
    }
    const data = query.data?.shop?.metafield?.value
      ? JSON.parse(query.data.shop.metafield.value)
      : [];
    return data.length === 2
      ? {
          desktop_columns: data[0],
          mobile_columns: data[1],
        }
      : {
          desktop_columns: 4,
          mobile_columns: 2,
        };
  }, [query.data?.shop?.metafield?.value, query.isPending]);

  return {
    ...query,
    data: columnSettings,
  };
}
