import { css, useTheme } from "@emotion/react";
import { colorPalette } from "src/theme";

interface AvatarProps {
  persona: string;
}
export const Avatar = (props: AvatarProps) => {
  const theme = useTheme();

  // Take the first letters of the first and second strings, separated by a space
  const initials = props.persona
    .replace(" & ", " ")
    .split(" ")
    .map((word) => word.slice(0, 1))
    .slice(0, 2)
    .join("")
    .toLocaleUpperCase();

  return (
    <div
      css={css`
        display: flex;
        width: 40px;
        height: 40px;
        padding: 16px 0px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 999px;
        background: ${colorPalette.blue["200"]};
      `}
    >
      <p
        css={[
          theme.typography["label-m"],
          css`
            color: ${colorPalette.blue["950"]};
            text-align: center;
            font-feature-settings:
              "ss11" on,
              "cv09" on,
              "liga" off,
              "calt" off;
            margin: 0;
          `,
        ]}
      >
        {initials}
      </p>
    </div>
  );
};
