import { Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";
import config from "src/config";

const getClient = (() => {
  let client: Auth0Client | undefined;
  return async () =>
    (client ??= await (async () =>
      await createAuth0Client({
        domain: config.auth0.domain,
        clientId: config.auth0.clientId,
        authorizationParams: {
          redirect_uri: window.location.origin + config.publicUrl,
        },
      }))());
})();

export const logout = async () => {
  const client = await getClient();
  client.logout({
    logoutParams: {
      returnTo: window.location.origin + config.publicUrl,
    },
  });
};

function isAuth0Error(error: unknown): error is { error: string } {
  return typeof error === "object" && error !== null && "error" in error;
}

export const getIdTokenSilently = async () => {
  const client = await getClient();

  try {
    const response = await client.getTokenSilently({
      detailedResponse: true,
      authorizationParams: {
        redirect_uri: window.location.origin + config.publicUrl,
      },
    });

    return response.id_token;
  } catch (error) {
    if (isAuth0Error(error) && error.error === "login_required") {
      await client.loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin + config.publicUrl,
        },
      });
    } else {
      throw error;
    }
  }
  return "";
};
