import { useSuspenseQuery } from "@tanstack/react-query";
import useAuthorizedApi from "../../helpers/hooks/app/useAuthorizedApi";
import useMerchantId from "../../helpers/hooks/app/useMerchantId";
import { getData } from "../../api/authorizedApi";
import { getLiteQueryKey, QueryId } from "../queries";
import { useMemo } from "react";

export default function usePlanStatus() {
  const { api } = useAuthorizedApi();
  const { merchantId } = useMerchantId();
  const { data } = useSuspenseQuery({
    queryKey: getLiteQueryKey(QueryId.GetSubscriptionStatus, { merchantId }),
    queryFn: async () => {
      const res = await api.GET("/settings/subscription/status", {
        params: {
          query: {
            merchant_id: merchantId,
          },
        },
      });

      return getData(res);
    },
  });

  return data;
}

export function useAccountFrozen() {
  const { frozen, subscriptions } = usePlanStatus();

  // Only disable functionality for merchants with no subscriptions.
  return frozen && !subscriptions?.length;
}
