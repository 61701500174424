import { useContext, useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { monitoringEnabled, setLoggedInUser } from "src/monitoring";
import { LiteUser } from "src/api/types";
import { useAnalytics } from "../integrations/segment";
import { AuthorizedMerchantsContext } from "src/contexts/AuthorizedMerchants.context";
import { usePostHog } from "posthog-js/react";

export function useSetupThirdPartiesThatDependOnUser(user?: LiteUser) {
  const { authorizedMerchants, loading: loadingMerchants } = useContext(
    AuthorizedMerchantsContext
  );
  const { update } = useIntercom();
  const analytics = useAnalytics();
  const fullName = user?.is_superuser
    ? user.email.split("@")[0]
    : authorizedMerchants?.[0].display_name ?? user?.email;
  const posthog = usePostHog();

  useEffect(() => {
    if (
      !loadingMerchants &&
      authorizedMerchants &&
      authorizedMerchants?.length > 0 &&
      user
    ) {
      setLoggedInUser(
        user.email!,
        authorizedMerchants[0].id,
        user.is_superuser
      );
    }
  }, [authorizedMerchants, loadingMerchants, user]);

  useEffect(() => {
    if (
      !loadingMerchants &&
      authorizedMerchants &&
      authorizedMerchants?.length > 0 &&
      user
    ) {
      analytics.identify(user.email, {
        name: fullName,
        email: user.email,
        company: { id: authorizedMerchants[0].id },
      });
    }
  }, [analytics, authorizedMerchants, loadingMerchants, user, fullName]);

  useEffect(() => {
    if (
      !loadingMerchants &&
      authorizedMerchants &&
      authorizedMerchants?.length > 0 &&
      user
    ) {
      posthog.identify(user.email, {
        name: fullName,
        email: user.email,
        company: { id: authorizedMerchants[0].id },
      });
    }
  }, [posthog, authorizedMerchants, loadingMerchants, user, fullName]);

  useEffect(() => {
    if (
      !loadingMerchants &&
      authorizedMerchants &&
      authorizedMerchants?.length > 0 &&
      user &&
      monitoringEnabled
    ) {
      update({
        name: fullName,
        email: user?.email.endsWith("depictai.com") ? undefined : user?.email, // Do not send email to intercom if it is a fake shopify email
        userHash: user.intercom_hash,
        company: {
          companyId: authorizedMerchants[0].id,
          name: authorizedMerchants[0].display_name,
        },
      });
    }
  }, [authorizedMerchants, loadingMerchants, update, user, fullName]);
}
