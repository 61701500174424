import { css, keyframes } from "@emotion/react";
import { IconProps, SpinnerGap } from "@phosphor-icons/react";

export const AnimatedSpinnerGap = (props: IconProps) => {
  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  const spinningIconStyle = css`
    animation: ${spin} 2s linear infinite;
  `;

  return <SpinnerGap {...props} css={spinningIconStyle} />;
};
