import { useDragAndDropContext } from "./DragAndDropProvider";
import { LiteCollectionProductDto } from "../../../../api/types";

/**
 * Because this sorting function is incredibly slow/expensive on large (50k+ products) collections and it's hard to optimise the usage, we just value first cache the function results here.
 */
const cache = new WeakMap<
  Set<string>,
  WeakMap<
    (string | number | null)[],
    WeakMap<
      LiteCollectionProductDto[],
      ReturnType<typeof actualGetSortByGridOrderPrioritiseBlocks>
    >
  >
>();

/**
 * Sorts the provided grid item ids by the order in grid, but puts all content blocks first. This should reflect the order of the card stack.
 */
export function useSortByGridOrderPrioritiseBlocks(idsToSort: Set<string>) {
  const { occupiedByBlocksOrProducts, productsToRender } =
    useDragAndDropContext();
  return getSortByGridOrderPrioritiseBlocks(
    idsToSort,
    occupiedByBlocksOrProducts,
    productsToRender
  );
}

/**
 * Sorts the provided grid item ids by the order in grid, but puts all content blocks first. This should reflect the order of the card stack and how things get dropped.
 */
export function getSortByGridOrderPrioritiseBlocks(
  idsToSort: Set<string>,
  occupiedByBlocksOrProducts: (string | number | null)[],
  productsToRender: LiteCollectionProductDto[]
) {
  const cached = cache.get(idsToSort);
  if (cached) {
    const cached2 = cached.get(occupiedByBlocksOrProducts);
    if (cached2) {
      const cached3 = cached2.get(productsToRender);
      if (cached3) {
        return cached3;
      }
    }
  }
  const result = actualGetSortByGridOrderPrioritiseBlocks(
    idsToSort,
    occupiedByBlocksOrProducts,
    productsToRender
  );

  let cachedByBlocks = cache.get(idsToSort);
  if (!cachedByBlocks) {
    cachedByBlocks = new WeakMap();
    cache.set(idsToSort, cachedByBlocks);
  }

  let cachedByOccupied = cachedByBlocks.get(occupiedByBlocksOrProducts);
  if (!cachedByOccupied) {
    cachedByOccupied = new WeakMap();
    cachedByBlocks.set(occupiedByBlocksOrProducts, cachedByOccupied);
  }

  cachedByOccupied.set(productsToRender, result);

  return result;
}

/**
 * Actual implementation of the sorting function, without caching
 */
function actualGetSortByGridOrderPrioritiseBlocks(
  idsToSort: Set<string>,
  occupiedByBlocksOrProducts: (string | number | null)[],
  productsToRender: LiteCollectionProductDto[]
) {
  const blockIds = new Set<string>();
  // Map for faster lookup, also we need to actually get ids of all grid positions
  const occupiedIdByIndex = new Map<string, number>();

  // Go backwards, so for blocks that span multiple "grid slots" we end up saving the earliest one
  for (let i = occupiedByBlocksOrProducts.length - 1; i >= 0; i--) {
    const item = occupiedByBlocksOrProducts[i];
    if (item === null) continue;
    let idOfItem: string;
    if (typeof item === "number") {
      idOfItem = productsToRender[item].main_product_id;
    } else {
      idOfItem = item;
      blockIds.add(idOfItem);
    }
    occupiedIdByIndex.set(idOfItem, i);
  }

  const sortedByGridOrder = [...idsToSort].sort((a, b) => {
    const aIndex = occupiedIdByIndex.get(a)!;
    const bIndex = occupiedIdByIndex.get(b)!;
    return aIndex - bIndex;
  });

  const blocks = sortedByGridOrder.filter((id) => blockIds.has(id));
  const products = sortedByGridOrder.filter((id) => !blockIds.has(id));

  return {
    /**
     * blocks sorted first, then products sorted
     */
    allSortedPrioritised: [...blocks, ...products],
    /**
     * Everything actually in the grid order
     */
    allSorted: sortedByGridOrder,
    blocks,
    products,
  };
}
