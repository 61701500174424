import { CSSInterpolation } from "@emotion/css";
import { css, useTheme } from "@emotion/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

interface SidebarItemLinkProps {
  title: string;
  leftIcon?: (props: { css: Array<CSSInterpolation> }) => JSX.Element;
  rightIcon?: React.ReactNode;
  collapsed: boolean;
  onClick?: () => void;
}
export const SidebarItemLink = (
  props: SidebarItemLinkProps & { isActive: boolean }
) => {
  const theme = useTheme();
  const { isActive } = props;

  return (
    <div
      onClick={props.onClick}
      css={[
        theme.typography["label-s"],
        css`
          ${props.collapsed
            ? css`
                width: min-content;
              `
            : css`
                width: 100%;
              `}
          display: flex;
          padding: 8px;
          align-items: center;
          border-radius: 8px;
          gap: 8px;
          background: ${isActive
            ? theme.colors.bg["weak-50"]
            : theme.colors.bg["white-0"]};
          cursor: pointer;
        `,
        css`
          &:hover {
            background: ${theme.colors.bg["weak-50"]};
          }
        `,
      ]}
    >
      {props.leftIcon?.({
        css: [
          css`
            width: 20px;
            height: 20px;
            min-width: 20px;
          `,
          isActive &&
            css`
              color: ${theme.colors.primary.base};
            `,
        ],
      })}
      <AnimatePresence>
        {!props.collapsed && (
          <>
            <motion.div
              css={css`
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              `}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              {props.title}
            </motion.div>
            {props.rightIcon}
          </>
        )}
      </AnimatePresence>
    </div>
  );
};
