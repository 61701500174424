import { css } from "@emotion/react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import SidebarItem, { SidebarItemProps } from "./SidebarItem";

interface NavigationProps {
  items: SidebarItemProps[];
}
export const Navigation = (props: NavigationProps) => {
  return (
    <NavigationMenu.Root
      orientation="vertical"
      css={css`
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
      `}
    >
      <NavigationMenu.List
        css={css`
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-self: stretch;
        `}
      >
        {props.items.map((item) => (
          <SidebarItem key={item.title} {...item} />
        ))}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};
