import useAuthorizedMerchants from "./helpers/hooks/app/useAuthorizedMerchants";
import { AuthorizedMerchantsContext } from "./contexts/AuthorizedMerchants.context";
import DepictLiteApp from "./DepictLiteApp";
import SplashScreen from "./lite/components/SplashScreen/SplashScreen";
import { Suspense } from "react";
import NeueMontreal from "./lite/views/NeueMontreal";
import AlertsContainer from "./alignUI/Alert/AlertsContainer";
import GlobalStyles from "./GlobalStyles";
import createCache from "@emotion/cache";
import { CacheProvider, EmotionCache } from "@emotion/react";

const cache = createCache({ key: "depict", speedy: false }) as EmotionCache;

const App = () => {
  const [authorizedMerchants, loadingMerchants] = useAuthorizedMerchants();

  return (
    <CacheProvider value={cache}>
      <AuthorizedMerchantsContext.Provider
        value={{
          authorizedMerchants,
          loading: loadingMerchants,
        }}
      >
        <Suspense fallback={<SplashScreen />}>
          <NeueMontreal />
          <GlobalStyles />
          <DepictLiteApp />
        </Suspense>
        <AlertsContainer />
      </AuthorizedMerchantsContext.Provider>
    </CacheProvider>
  );
};

export default App;
