import { css, useTheme } from "@emotion/react";

interface ContentDividerProps {
  text?: string;
  icon?: React.ReactNode;
}

export const ContentDivider = (props: ContentDividerProps) => {
  const theme = useTheme();

  if (props.text || props.icon) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 100%;
          height: fit-content;
        `}
      >
        <div
          css={css`
            width: 100%;
            height: 1px;
            background-color: ${theme.colors.stroke["soft-200"]};
          `}
        />
        {props.icon && props.icon}
        {props.text && (
          <span
            css={[
              theme.typography["subheading-xxs"],
              css`
                color: ${theme.colors.text["soft-400"]};
                white-space: nowrap;
              `,
            ]}
          >
            {props.text}
          </span>
        )}
        <div
          css={css`
            width: 100%;
            height: 1px;
            background-color: ${theme.colors.stroke["soft-200"]};
          `}
        />
      </div>
    );
  }

  return (
    <div
      css={css`
        width: 100%;
        height: 1px;
        background-color: ${theme.colors.stroke["soft-200"]};
      `}
    />
  );
};
